import { useState } from "react";
import "./Player.css";
import Slider from "../Slider/Slider";

const svgViewBox = "2 2 16 16";

function Play() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={svgViewBox}
            fill="currentColor"
        >
            {/* d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" */}
            <path
                fillRule="evenodd"
                d="M9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function Pause() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={svgViewBox}
            fill="currentColor"
        >
            {/* d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" */}
            <path
                fillRule="evenodd"
                d="M7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function Next() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={svgViewBox}
            fill="currentColor"
        >
            {/* d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" */}
            {/* M10 8m5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z */}
            <path
                fillRule="evenodd"
                d="M7.555 7.168a1 1 0 00 -1.555 0.832v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z M8 8m5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function Previous() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={svgViewBox}
            fill="currentColor"
        >
            <path
                style={{
                    transformBox: "fill-box",
                    transformOrigin: "center",
                    transform: "rotate(180deg)",
                }}
                fillRule="evenodd"
                d="M7.555 7.168a1 1 0 00 -1.555 0.832v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z M8 8m5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
            />
        </svg>
    );
}

const baseButtonClass =
    "mr-2 ml-2 rounded-full w-10 h-10 inline-flex items-center justify-center transition-colors";

const buttonClass = `${baseButtonClass} play-btn bg-green-500 hover:bg-green-400 text-black font-bold disabled:bg-gray-300`;

const bgClass =
    "bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-60 bg-gray-800 pt-4 px-8 rounded-md";

const iconButtonClass = `${baseButtonClass} text-white duration-150 focus:shadow-outline bg-gray-600 bg-opacity-0 hover:bg-opacity-60`;

export interface PlayerProps {
    disabled?: boolean;
    onClick?: () => void;
}

export default function Player(props: PlayerProps): JSX.Element {
    let [playing, setPlaying] = useState(false);
    return (
        <div className={`flex-col z-50 ${bgClass}`}>
            <div className="flex justify-evenly items-center px-8">
                <button className={iconButtonClass}>{Previous()}</button>
                <button
                    disabled={props.disabled}
                    className={buttonClass}
                    onClick={() => {
                        // setPlaying(!playing);
                        if (props.onClick) {
                            props.onClick();
                        }
                    }}
                >
                    {playing ? Pause() : Play()}
                </button>
                <button className={iconButtonClass}>{Next()}</button>
            </div>
            <div className="flex">
                <Slider></Slider>
            </div>
        </div>
    );
}
