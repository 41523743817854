import {
    BinaryExpression,
    Block,
    CallExpression,
    ConditionalExpression,
    FunctionDeclaration,
    Node,
    ParenthesizedExpression,
    PrefixUnaryExpression,
    Project,
    ReturnStatement,
    SourceFile,
    SyntaxKind,
    ts,
} from "ts-morph";

export function morphPosToCm(pos: { line: number; column: number }) {
    return {
        line: pos.line - 1,
        ch: pos.column - 1,
    };
}

export function cmPosToMorphIndex(
    file: SourceFile,
    pos: { line: number; ch: number },
) {
    return file.compilerNode.getPositionOfLineAndCharacter(
        pos.line,
        pos.ch - 1,
    );
}

export function createProject() {
    return new Project({
        useInMemoryFileSystem: true,
    });
}

export function createFile(
    code = "",
    path = "index.ts",
    project: Project = createProject(),
) {
    return project.createSourceFile(path, code);
}

export function canBeReplaced(file: SourceFile, location: number) {
    let node = file.getDescendantAtPos(location);
    // Is this a function call?
    let call = node?.getFirstAncestorByKind(SyntaxKind.CallExpression);
    // If it is, what's the function body?
    if (call) {
        return call;
    }

    return node ? getSimplifiable(node) : undefined;
}

const simplifiableLeafKinds = [
    SyntaxKind.NumericLiteral,
    SyntaxKind.TrueKeyword,
    SyntaxKind.FalseKeyword,
    SyntaxKind.NullKeyword,
    SyntaxKind.UndefinedKeyword,
];
export function isSimplifiableLeaf(node: Node | undefined): boolean {
    return (
        node !== undefined &&
        ((Node.isPrefixUnaryExpression(node) &&
            isSimplifiableLeaf(node.getOperand())) ||
            (node.getChildCount() === 0 &&
                simplifiableLeafKinds.includes(node.getKind())))
    );
}

export function getSimplifiable(node: Node): Node | void {
    let parent = node.getParent();
    if (!parent) return;
    switch (parent.getKind()) {
        case SyntaxKind.BinaryExpression:
            let left = (parent as BinaryExpression).getLeft();
            let right = (parent as BinaryExpression).getRight();
            if (isSimplifiableLeaf(left) && isSimplifiableLeaf(right)) {
                return parent;
            }
            return;
        case SyntaxKind.ConditionalExpression:
            let condExpr = parent as ConditionalExpression;
            if (isSimplifiableLeaf(condExpr.getCondition())) {
                return condExpr;
            }
            return;
        case SyntaxKind.PrefixUnaryExpression:
            let prefixExpr = parent as PrefixUnaryExpression;
            if (isSimplifiableLeaf(prefixExpr.getOperand())) {
                return getSimplifiable(parent);
            }
            return;
        case SyntaxKind.ParenthesizedExpression:
            let parenExpr = parent as ParenthesizedExpression;
            let inner = parenExpr.getExpression();
            if (
                inner.getKind() === SyntaxKind.ParenthesizedExpression ||
                isSimplifiableLeaf(inner)
            ) {
                return parenExpr;
            }
            return;
    }
}

export function simplifyEval(node: Node): string | void {
    let result = eval(node.getText());
    if (
        ["string", "number", "undefined", "boolean"].includes(typeof result) ||
        result === null
    ) {
        return JSON.stringify(result);
    }
}

export function simplify(node: Node): string | void {
    switch (node.getKind()) {
        case SyntaxKind.PrefixUnaryExpression:
        case SyntaxKind.BinaryExpression:
            return simplifyEval(node);
        case SyntaxKind.ConditionalExpression:
            let condExpr = node as ConditionalExpression;
            let cond = condExpr.getCondition();
            if (isSimplifiableLeaf(cond)) {
                try {
                    let result = eval(cond.getText());
                    return result
                        ? condExpr.getWhenTrue().getText()
                        : condExpr.getWhenFalse().getText();
                } catch (e) {}
            }
            return;
        case SyntaxKind.ParenthesizedExpression:
            let parenExpr = node as ParenthesizedExpression;
            let inner = parenExpr.getExpression();
            if (
                inner.getKind() === SyntaxKind.ParenthesizedExpression ||
                isSimplifiableLeaf(inner)
            ) {
                return inner.getText();
            }
            return;
    }
}

export function getReplaceableNode(file: SourceFile, location: number) {
    let node = file.getDescendantAtPos(location);
    // Is this a function call?
    let call = node?.getFirstAncestorByKind(SyntaxKind.CallExpression);

    if (
        call &&
        location >= call.getStart(false) - 1 &&
        location <= call.getEnd()
    ) {
        // If it is, what's the function body?
        return call;
    }

    if (node) {
        let simplifiable = getSimplifiable(node);
        let parent = simplifiable?.getParent();
        if (parent && parent.getKind() === SyntaxKind.ParenthesizedExpression) {
            return parent;
        }
        return simplifiable;
    }
}

function functionBody(declaration: FunctionDeclaration): Node | undefined {
    let body = declaration.getBody();

    if (!body || body.getKind() !== SyntaxKind.Block) return;

    let block = body as Block;

    let statements = block.getStatements();

    if (statements.length !== 1) return;

    let statement = statements[0];

    if (statement.getKind() !== SyntaxKind.ReturnStatement) return;

    let ret = statement as ReturnStatement;

    let retExp = ret.getExpression();

    return retExp;

    // if (!retExp) return;

    // let txt = retExp.getText();
}

function replacingArgs(
    declaration: FunctionDeclaration,
    replaceWith: string[],
): string | undefined {
    let body = functionBody(declaration);
    if (!body) return;

    let content = body.getText();
    let start = body.getStart();

    let spans = declaration
        .getParameters()
        .flatMap((p, i) =>
            p
                .findReferences()
                .flatMap((r) => r.getReferences())
                .map((r) => ({ span: r.getTextSpan(), index: i })),
        )
        .sort((a, b) => {
            let spanStart = a.span.getStart() - b.span.getStart();
            if (spanStart === 0) {
                return a.span.getEnd() - b.span.getEnd();
            }
            return spanStart;
        })
        .filter(
            // Can also check if previous.end < curr.start (or <=??)
            (span, i, spans) =>
                span.span.getStart() >= start &&
                (i === 0 ||
                    spans[i - 1].span.getStart() !== span.span.getStart() ||
                    spans[i - 1].span.getEnd() !== span.span.getEnd()),
        );

    let lastPos = start;
    let replaced = "";
    spans.forEach((span) => {
        if (span.span.getStart() < lastPos) return;
        replaced += content.substring(
            lastPos - start,
            span.span.getStart() - start,
        );
        // TODO: instead of using [0], each span should have something specified
        replaced += replaceWith[span.index];
        lastPos = span.span.getEnd();
    });
    replaced += content.substring(lastPos - start, body.getEnd() - start);
    return replaced;
}

export function replaceCall(
    call: CallExpression<ts.CallExpression>,
): string | void {
    let args = call.getArguments().map((arg) => arg.getText());
    let declaration = call.getExpression().getSymbol()?.getDeclarations()?.[0];
    if (
        !declaration ||
        declaration.getKind() !== SyntaxKind.FunctionDeclaration
    ) {
        return;
    }
    let fn = declaration as FunctionDeclaration;
    let replaced = replacingArgs(fn, args);
    return `(${replaced})`;
    // if (typeof txt !== "string") return;

    // let file = call.getSourceFile();
    // file.replaceText([call.getStart(), call.getEnd()], `(${txt})`);
    // return file.getFullText();

    // let body = fn.getBody();

    // if (!body || body.getKind() !== SyntaxKind.Block) return;

    // let block = body as Block;

    // let statements = block.getStatements();

    // if (statements.length !== 1) return;

    // let statement = statements[0];

    // if (statement.getKind() !== SyntaxKind.ReturnStatement) return;

    // let ret = statement as ReturnStatement;

    // let retExp = ret.getExpression();

    // if (!retExp) return;

    // let txt = retExp.getText();

    // let file = call.getSourceFile();
    // file.replaceText([call.getStart(), call.getEnd()], `(${txt})`);
    // return file.getFullText();
    // // call.replaceWithText("/*" + declaration.getText() + "*/");
}

export function replace(node: Node) {
    const range: [number, number] = [node.getStart(), node.getEnd()];
    if (Node.isParenthesizedExpression(node)) {
        let innerExpr = node.getExpression();
        node = node.getExpression();
    }
    // TODO: what if node is a call inside parenthesis?
    let txt: string | void;
    if (Node.isCallExpression(node)) {
        txt = replaceCall(node);
    } else {
        txt = simplify(node);
    }

    if (typeof txt !== "string") return;

    let file = node.getSourceFile();
    file.replaceText(range, txt);
    return file.getFullText();
}

function asb() {
    // I need to get the info from ts-morph
    // I need to do a replacement
}
