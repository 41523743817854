import {
    MouseEvent,
    MouseEventHandler,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import "./Mathsub.css";

import { useLocalStorage } from "../../lib/use-storage";
import {
    canBeReplaced,
    morphPosToCm,
    createFile,
    getReplaceableNode,
    cmPosToMorphIndex,
    replace,
} from "../../lib/mathsub";
import CodeMirror from "@uiw/react-codemirror";
import "codemirror/keymap/sublime";
import "codemirror/theme/icecoder.css";
import ReactCodemirror from "@uiw/react-codemirror";
import Player from "../Player/Player";
import { countColumn } from "codemirror";

function Mathsub() {
    let [code, setCode] = useLocalStorage("mathsub_code", "");

    const [file] = useState(createFile(code));
    const [selection, setSelection] = useState<number | undefined>(undefined);

    let handleCodeChange = useMemo(
        () =>
            (
                instance: CodeMirror.Editor,
                change: CodeMirror.EditorChange[],
            ) => {
                console.log("FN handleCodeChange");
                // let newCode = e.target.value;
                let newCode = instance.getValue();
                setCode(newCode);
                file.replaceWithText(newCode);
            },
        [setCode, file],
    );

    let handleSelectionChange = useMemo(
        // () => (e: SyntheticEvent<HTMLTextAreaElement, Event>) => {
        () => (instance: CodeMirror.Editor) => {
            console.log("FN handleSelectionChange");
            // let el = e.nativeEvent.target as HTMLTextAreaElement;
            // if (el.selectionEnd == el.selectionStart) {
            //     setSelection(el.selectionStart);
            // } else {
            //     setSelection(undefined);
            // }
            let cursor = instance.getCursor();
            let sel = cmPosToMorphIndex(file, cursor);
            setSelection(sel);
            // let newCode = e.target.value;
            // setCode(newCode);
            // file.replaceWithText(newCode);
        },
        [file],
    );

    let canReplace = useMemo(() => {
        console.log("FN canReplace");
        return typeof selection === "number"
            ? canBeReplaced(file, selection)
            : false;
    }, [file, selection]);

    let replaceableNode = useMemo(() => {
        console.log("FN replaceableNode");
        if (typeof selection !== "number") return undefined;
        let node = getReplaceableNode(file, selection);
        return node;
    }, [file, code, selection]);

    // let nodeDebug = useMemo(() => {
    //     return (
    //         replaceableNode
    //             ?.getExpression()
    //             .getSymbol()
    //             ?.getDeclarations()
    //             ?.map((decl) => decl.getKindName())
    //             ?.join("\n") ?? ""
    //     );
    // }, [replaceableNode]);

    let codemirrorRef = useRef<ReactCodemirror>(null);

    function clearReplaceableMarks(editor: CodeMirror.Editor) {
        editor.getAllMarks().forEach((m) => {
            if (m.title === "replaceable") {
                m.clear();
            }
        });
    }

    // MouseEventHandler<HTMLButtonElement>
    let handleReplaceClick: () => void = useMemo(
        () => () => {
            if (!replaceableNode) return;
            let newCode = replace(replaceableNode);
            if (typeof newCode === "string") {
                setCode(newCode);
            }
        },
        [replaceableNode, setCode],
    );

    useEffect(() => {
        console.log("EFFECT!");
        let editor = codemirrorRef.current?.editor;
        if (!editor) return;
        clearReplaceableMarks(editor);
        if (!replaceableNode || replaceableNode.wasForgotten()) return;
        let start = morphPosToCm(
            file.getLineAndColumnAtPos(replaceableNode.getStart()),
        );
        let end = morphPosToCm(
            file.getLineAndColumnAtPos(replaceableNode.getEnd()),
        );
        editor.getDoc().markText(start, end, {
            className: "replaceable",
            startStyle: "replaceable-start",
            endStyle: "replaceable-end",
            // clearWhenEmpty: false,
            // clearOnEnter: false,
            // collapsed: false,
            // handleMouseEvents: false,
            // readOnly: true,
            title: "replaceable",
        });
    }, [replaceableNode, codemirrorRef.current]);

    // Set line wrapping
    useEffect(() => {
        let editor = codemirrorRef.current?.editor;
        if (!editor) return;
        var charWidth = editor.defaultCharWidth(),
            basePadding = 4;
        editor.on("renderLine", function (cm, line, elt) {
            var off =
                countColumn(line.text, null, cm.getOption("tabSize") ?? 4) *
                charWidth;
            elt.style.textIndent = "-" + off + "px";
            elt.style.paddingLeft = basePadding + off + "px";
        });
    }, [codemirrorRef.current]);

    return (
        <div className="app-layout">
            <CodeMirror
                ref={codemirrorRef}
                value={code}
                onChange={handleCodeChange}
                onCursorActivity={handleSelectionChange}
                options={{
                    mode: "ts",
                    theme: "icecoder",
                    lineWrapping: true,
                }}
            ></CodeMirror>
            <div
                className="fixed flex justify-center"
                style={{ bottom: "32px", left: 0, right: 0 }}
            >
                <Player
                    disabled={!canReplace}
                    onClick={handleReplaceClick}
                ></Player>
            </div>
            {/* <div>
                <button disabled={!canReplace} onClick={handleReplaceClick}>
                    Replace
                </button>
            </div> */}
        </div>
    );
}

export default Mathsub;
